export const removeMask = (value: string | null) => {
    return value?.replace(/\D/g, "") ?? ''
  };

  export const removePlateMask = (value: string | null) => {
    return value?.replace(/_/g, "") ?? ''
  };

  export const sanitizerName = (nome: string) => {
    if(nome !== '' && nome !== undefined && nome !== null  ){
      const nameArray = nome.trim().split(' ')
      const mapNome = nameArray.map(n => {
        return n[0].toUpperCase() + n.slice(1).toLocaleLowerCase()
      })
      return  mapNome.join(' ')
    }
    return ''
  }