import PrivateRoute from "../../core/PrivateRouter";
import SchedulingTracksRegisterPage from "./pages/SchedulingTracksRegister";
import SearchTracksPage from "./pages/SearchTracksPage";
import TracksInstallationPage from "./pages/TrackInstallation";
import TrackServices from "./pages/TrackServices";
import TracksListPage from "./pages/TracksListPage";
import TracksPage from "./pages/TracksPage";
import TracksRegisterPage from "./pages/TracksRegister";
import OldTracksRegisterPage from "./pages/TracksRegister/OldTrackRegister";

export const tracksRoutes = [
    { path: '/tracks-register-complete', element: <PrivateRoute Group={['integrador']}><OldTracksRegisterPage /></PrivateRoute> },
    { path: '/cadastro/rastreador/completo', element: <PrivateRoute Group={['integrador']}><OldTracksRegisterPage /></PrivateRoute> },
    { path: '/tracks-register', element: <PrivateRoute Group={['integrador']}><TracksRegisterPage /></PrivateRoute> },
    { path: '/cadastro/rastreador', element: <PrivateRoute Group={['integrador']}><TracksRegisterPage /></PrivateRoute> },
    { path: '/scheduling/tracks-register', element: <PrivateRoute Group={['integrador']}><SchedulingTracksRegisterPage /></PrivateRoute> },
    { path: '/agendamento/cadastro/rastreador', element: <PrivateRoute Group={['integrador']}><SchedulingTracksRegisterPage /></PrivateRoute> },
    { path: '/ativar/rastreamento', element: <PrivateRoute Group={["teleatendente"]}><SearchTracksPage /></PrivateRoute> },
    { path: "/tracks", element: <PrivateRoute Group={['cidadão']}><TracksPage /></PrivateRoute>},
    { path: "/rastreadores", element: <PrivateRoute Group={['cidadão']}><TracksPage /></PrivateRoute>},
    { path: "/admin/rastreadores", element: <PrivateRoute Group={['integrador']}><TracksListPage /></PrivateRoute>},
    { path: '/services/installation', element: <PrivateRoute Group={['integrador']}><TracksInstallationPage /></PrivateRoute> },
    { path: '/servicos/instalacao', element: <PrivateRoute Group={['integrador']}><TracksInstallationPage /></PrivateRoute> },
    { path: '/services', element: <PrivateRoute Group={['integrador']}><TrackServices /></PrivateRoute> },
    { path: '/servicos', element: <PrivateRoute Group={['integrador']}><TrackServices /></PrivateRoute> },

];