import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface TrackSummaryProps {
  readonly trackId: string;
  readonly trackChip: string;
  readonly brand: string | null;
  readonly image: string | ArrayBuffer | ImageData | null;
}

export default function TrackSummary({
  trackChip,
  trackId,
  brand,
  image,
}: TrackSummaryProps) {
  return (
    <>
      <Grid size={12}>
        <Typography variant="h6" fontWeight={"bold"}>
          Dados Rastreador
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography>
          <b>Código do Rastreador:</b> {trackId}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography>
          <b>Chip do Rastreador:</b> {trackChip}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography>
          <b>Marca do Rastreador:</b> {brand ? brand : "Não informado"}
        </Typography>
      </Grid>
      {image ? (
        <Grid
          container
          size={12}
          display={"flex"}
          justifyContent={"center"}
          sx={{ minHeight: "30vh", marginTop: 2 }}
        >
          <Grid size={{ sm: 6, lg: 4 }}>
            <Box
              component={"img"}
              sx={{
                maxHeight: { xs: "100%", sm: "80%", md: "100%" },
                maxWidth: { xs: "100%", sm: "80%", md: "100%" },
              }}
              src={image as string}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid size={{ xs: 12, sm: 6, md: 3 }}>
          <Typography>
            <b>Foto:</b> Não informado
          </Typography>
        </Grid>
      )}
    </>
  );
}
