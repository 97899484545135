import PrivateRoute from "../../core/PrivateRouter";
import CitizenListPage from "./pages/CitizenListPage";
import CitizenRegisterPage from "./pages/CitizenRegister";



export const citizenRoutes = [
    { path: '/admin/cidadaos', element: <PrivateRoute><CitizenListPage /></PrivateRoute> },
    { path: '/citizen-register', element: <CitizenRegisterPage /> },
    { path: '/cadastro/cidadao', element: <CitizenRegisterPage /> },
];
