import React from "react";
import ListLinkSideBar from "./ListLinkSideBar";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';


export default function MenuTeleatendende({hiddenMenuLabel=false,  onMenuClick = (menuItem:string)=>{}, activeMenu= ''}) {
   
  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem)
  };


  return( 
  <>
    <ListLinkSideBar
    //ctiveMenu={activeMenu === "ativar-rastreamento"}
    icon={<TrackChangesIcon fontSize="medium" />}
    text={"Ativar Rastreamento"}
    showText={!hiddenMenuLabel}
    link="/ativar/rastreamento"
    handleClick={() => handleMenuClick("ativar-rastreamento")}
  />
  </>
)
}
