import React from "react";
import ListLinkSideBar from "./ListLinkSideBar";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { useAuthetication } from "../../Contexts/AutheticationContext";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';

export default function MenuIntegrador({
  hiddenMenuLabel = false,
  onMenuClick = (menuItem: string) => {},
  activeMenu = "",
}) {
  const { userRoles } = useAuthetication();

  const handleMenuClick = (menuItem: string) => {
    onMenuClick(menuItem);
  };

  return (
    <>
      {/* <ListLinkSideBar
        //activeMenu={activeMenu === "/scheduling"}
        icon={<CalendarMonthOutlinedIcon fontSize="medium" />}
        text={"Agendamentos"}
        showText={!hiddenMenuLabel}
        link="/agendamento"
        handleClick={() => handleMenuClick("/agendamento")}
      /> */}
      <ListLinkSideBar
        //activeMenu={activeMenu === "citizen-register"}
        icon={<PersonAddAlt1OutlinedIcon fontSize="medium" />}
        text={"Cadastro do Cidadão"}
        showText={!hiddenMenuLabel}
        link="/cadastro/cidadao"
        handleClick={() => handleMenuClick("/cadastro/cidadao")}
      />
      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<AddLocationAltOutlinedIcon fontSize="medium" />}
        text={"Cadastro do Rastreador"}
        showText={!hiddenMenuLabel}
        link="/cadastro/rastreador"
        handleClick={() => handleMenuClick("/cadastro/rastreador")}
      />
      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<WhereToVoteOutlinedIcon fontSize="medium" />}
        text={"Cadastro Completo"}
        showText={!hiddenMenuLabel}
        link="/cadastro/rastreador/completo"
        handleClick={() => handleMenuClick("tracks-register-complete")}
      />
      {/* <ListLinkSideBar
        //activeMenu={activeMenu === "rastreadores"}
        icon={<ShareLocationOutlinedIcon fontSize="medium" />}
        text={"Rastreadores"}
        showText={!hiddenMenuLabel}
        link="/admin/rastreadores"
        handleClick={() => handleMenuClick("/admin/rastreadores")}
      /> */}
      {userRoles.includes("administrador") && (
        <ListLinkSideBar
          icon={<GroupsOutlinedIcon fontSize="medium" />}
          text={"Cadastro Operador"}
          showText={!hiddenMenuLabel}
          link="/operador"
          handleClick={() => handleMenuClick("/operador")}
        />
      )}
      <ListLinkSideBar
        //activeMenu={activeMenu === "tracks-register"}
        icon={<HomeRepairServiceOutlinedIcon fontSize="medium" />}
        text={"Serviços"}
        showText={!hiddenMenuLabel}
        link="/services"
        handleClick={() => handleMenuClick("services")}
      />

      {/* {userRoles.includes('administrador') && (

             <ListLinkSideBar
            activeMenu={activeMenu === "/credenciada"}
            icon={<BusinessIcon fontSize="medium" />}
            text={"Cadastro Credenciada"}
            showText={!hiddenMenuLabel}
            link="/credenciadas/criar"
            handleClick={() => handleMenuClick("credenciada")}
          /> 
          )} */}
    </>
  );
}
