import { authFetch } from "../../../services/fetch";

type RequestProps = {
    page?: number;
    limit?: number;
    filters?: {
        company_branch_id?: number;
        datetime?: string;
    }
}

export type Appointment = {
    "id": number;
    "person_name": string;
    "person_cpf": string;
    "plate": string;
    "person_birthdate": string;
    "person_phone": string;
    "person_email": string;
    "vehicle_purpose": string;
    "vehicle_type": string;
    "appointment_status_id": number;
    "service_type_id": number;
    "company_branch_id": number;
    "datetime": Date | string;
    "created_at": Date | string;
    "updated_at": Date | string;
    "deleted_at": Date | string | null;
    "appointment_external_id": number | null;
    "AppointmentStatus": {
        "id": number;
        "status_name": string;
        "status_key": string;
        "status_description": string;
        "created_at": Date | string;
        "updated_at": Date | string;
        "deleted_at": Date | string | null;
    },
    "ServiceType": {
        "id": number;
        "service_name": string;
        "service_key": string;
        "service_description": string;
        "created_at": Date | string;
        "updated_at": Date | string;
        "deleted_at": Date | string | null;
    },
    "companyBranch": {
        "id": number;
        "company_branch_name": string;
        "street": string;
        "neighborhood": string;
        "state": string;
        "number": string;
        "complement": string;
        "city": string;
        "zip_code": string;
        "created_at": Date | string;
        "updated_at": Date | string;
        "deleted_at": Date | string | null;
    }
}

export async function getAllAppointments({ page = 0, limit = 10, filters = {}}: RequestProps = {}): Promise<Appointment[]> {
    try {
        const { data } = await authFetch({
            url: "/appointment",
            method: "GET",
            params: {
                page: page,
                limit: limit,
                filters: JSON.stringify(filters)
            }
        });
        return data?.data ?? [];
    } catch (error) {
    throw error;
    }
}