import { Typography, IconButton, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import dayjs from "dayjs";
import { ImmutableObject, State, useHookstate } from "@hookstate/core";
import { ICitizen } from "../SearchCitizenTab";
import { useEffect } from "react";
import { getVehiclePurpose } from "../../../../components/Filters/FilterAutoCompletePrimaryPlatform/services";

interface CitizenSummaryProps{
    readonly openModalEdit: State<boolean>;
    readonly citizenDetails: ImmutableObject<ICitizen> | null;
}

export default function CitizenSummary({openModalEdit, citizenDetails}: CitizenSummaryProps) {
    const vehiclePurpose = useHookstate('');

    useEffect(() => {
        getVehiclePurpose().then((response) => {
            const vehicles = response.find(item => item.id === Number(citizenDetails?.vehiclePurposeId))
            vehiclePurpose.set(vehicles ? vehicles.name : '')
        });
      }, [citizenDetails]);
      
  return (
    <>
      <Grid size={12}>
        <Typography variant="h6" fontWeight={"bold"}>
          Dados Cidadão
          <IconButton
            title="Editar dados do cidadão"
            onClick={() => openModalEdit.set(true)}
            sx={{marginLeft: 1}}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Nome:</b> {citizenDetails?.name}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>CPF:</b>{" "}
          {citizenDetails?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Telefone:</b>{" "}
          {citizenDetails?.phone.replace(/(\d{2})(\d{5})(\d)/, "($1) $2-$3")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Email:</b> {citizenDetails?.email}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Data de Nascimento:</b>{" "}
          {dayjs(citizenDetails?.birthDate).format("DD/MM/YYYY")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>CEP:</b>{" "}
          {citizenDetails?.zip_code.replace(/(\d{5})(\d)/, "$1-$2")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Rua:</b> {citizenDetails?.street}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Número:</b> {citizenDetails?.number}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Complemento:</b>{" "}
          {citizenDetails?.complement?.length
            ? citizenDetails?.complement
            : "Não informado"}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Bairro:</b> {citizenDetails?.neighborhood}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Cidade:</b> {citizenDetails?.city}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Estado:</b> {citizenDetails?.state}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography sx={{overflowWrap: "break-word"}}>
          <b>Principal Plataforma:</b> {vehiclePurpose.get().length ? vehiclePurpose.get() : 'Não informado'}
        </Typography>
      </Grid>
    </>
  );
}
