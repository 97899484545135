import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import MainLayout from "../../../core/Layout/MainLayout";
import { useNavigate } from "react-router-dom";
import { createCitizen } from "../services";
import { useHookstate } from "@hookstate/core";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { getUser } from "../../../services/fetch";
import notify from "../../../utils/notify";
import ContainerLayout from "../../../core/Layout/ContainerLayout";
import TitleLayout from "../../../core/Layout/TitleLayout";
import { useForm } from "react-hook-form";
import ConfirmButton from "../../../components/Buttons/ConfirmButton";
import { Dayjs } from "dayjs";
import PersonalDataRegister from "../components/PersonalDataRegister";
import AddressDataRegister from "../components/AddressDataRegister";
import ApplicationDataRegister from "../components/ApplicationDataRegister";

function CitizenRegister() {
  const navigate = useNavigate();
  const user = getUser();
  const { handleSubmit } = useForm({ mode: "all" });

  const cep = useHookstate("");
  const loading = useHookstate<boolean>(false);
  const state = useHookstate("");
  const fullName = useHookstate("");
  const cpf = useHookstate("");
  const email = useHookstate("");
  const phone = useHookstate("");
  const birthDate = useHookstate<Dayjs | null>(null);
  const street = useHookstate("");
  const number = useHookstate("");
  const complement = useHookstate("");
  const neighborhood = useHookstate("");
  const city = useHookstate("");
  const principalPlatform = useHookstate<number | null>(null);
  const companyBranch = useHookstate<number | null>(null);

  const openConfirmDialog = useHookstate(false);
  const temporaryPassword = useHookstate("");
  const citizenId = useHookstate<string>("");

  const getFormData = () => ({
    full_name: fullName.get(),
    cpf: cpf.get(),
    phone: phone.get(),
    email: email.get(),
    birth_date: birthDate.get(),
    zip_code: cep.get(),
    street: street.get(),
    number: number.get(),
    complement: complement.get(),
    neighborhood: neighborhood.get(),
    city: city.get(),
    state: state.get(),
    vehiclePurposeId: principalPlatform.get(),
    companyBranchId: companyBranch.get(),
  });

  const handleFormErrors = (error: any) => {
    if (error?.invalid_params?.length) {
      error.invalid_params.forEach(
        ({ name, reason }: { name: string; reason: string }) => {
          if (name !== "username") {
            notify({
              message: reason,
              type: "error",
            });
          }
        }
      );
    } else if (error?.details?.length) {
      notify({
        message: error.details,
        type: "error",
      });
    } else {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    }
  };

  const handleSuccess = (data: any) => {
    citizenId.set(data.user.id);
    temporaryPassword.set(
      data.user.credentials ? data.user.credentials[0].value : ""
    );
    openConfirmDialog.set(true);
    notify({ message: `Usuário cadastrado com sucesso`, type: "success" });
  };

  const submitForm = async () => {
    try {
      loading.set(true);
  
      const body = getFormData();
  
      const { hasError, data, error } = await createCitizen(body);
  
      if (hasError) {
        handleFormErrors(error);
      } else {
        handleSuccess(data);
      }
    } catch (err) {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    } finally {
      loading.set(false);
    }
  };

  const handleAgree = () => {
    user
      ? navigate("/cadastro/rastreador", { state: { citizenId: citizenId.get() } })
      : navigate("/");
    openConfirmDialog.set(false);
  };

  const handleClose = () => {
    window.location.reload();
    openConfirmDialog.set(false);
  };

  const messageCitizenConfirmation = (
    <Typography>
      Para fazer login, utilizar o <b>CPF</b> e a senha temporária:
      <b>{temporaryPassword.get()}</b>
    </Typography>
  );

  const messageOperatorConfirmation = (
    <Grid container display={"flex"} gap={2}>
      <Typography>
        Para fazer login, o cidadão deve utilizar o <b>CPF</b> e a{" "}
        <b>senha temporária enviada por email</b>.
      </Typography>
      <Typography>Deseja cadastrar um rastreador para esse cidadão?</Typography>
    </Grid>
  );

  return (
    <MainLayout>
      <TitleLayout>Cadastro do Cidadão</TitleLayout>
      <ContainerLayout minHeight="50%">
        <Box
          component={"form"}
          onSubmit={handleSubmit(submitForm)}
          sx={{ width: "100%" }}
        >
          <Grid container size={12} display={"flex"} spacing={2}>
            <PersonalDataRegister
              birthDate={birthDate}
              cpf={cpf}
              email={email}
              fullName={fullName}
              phone={phone}
            />
            <AddressDataRegister
              cep={cep}
              city={city}
              complement={complement}
              neighborhood={neighborhood}
              number={number}
              state={state}
              street={street}
            />
            <ApplicationDataRegister
              principalPlatform={principalPlatform}
              companyBranch={companyBranch}
            />
          </Grid>

          <Grid
            container
            size={12}
            sx={{ gap: 1, marginTop: 2 }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Grid
              size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
              display={"flex"}
              sx={{ justifyContent: "flex-end" }}
            >
              <ConfirmButton type="submit" loading={loading.get()} />
            </Grid>
          </Grid>
        </Box>
      </ContainerLayout>
      <ConfirmDialog
        handleAgree={handleAgree}
        open={openConfirmDialog}
        title={
          user ? (
            <Typography variant="h6">
              Cidadão <b>{fullName.get()}</b> cadastrado com sucesso
            </Typography>
          ) : (
            "Cadastro realizado com sucesso"
          )
        }
        moreInformation={
          user ? messageOperatorConfirmation : messageCitizenConfirmation
        }
        handleClose={user ? handleClose : undefined}
        AgreeLabel="Cadastrar Rastreador"
        closeLabel="Não"
      />
    </MainLayout>
  );
}

export default CitizenRegister;
