import { Typography } from "@mui/material";
import InputField from "../../../../components/Field";
import { removeMask } from "../../../../utils/stringsManipulations";
import Grid from "@mui/material/Grid2";
import { State } from "@hookstate/core";
import InputMask from "react-input-mask";
import { useEffect } from "react";
import { getAddressByCep } from "../../services";
import notify from "../../../../utils/notify";

interface AddressDataRegisterProps {
  readonly cep: State<string>;
  readonly street: State<string>;
  readonly number: State<string>;
  readonly complement: State<string>;
  readonly neighborhood: State<string>;
  readonly city: State<string>;
  readonly state: State<string>;
}

export default function AddressDataRegister({
  cep,
  street,
  number,
  complement,
  neighborhood,
  city,
  state,
}: AddressDataRegisterProps) {

  useEffect(() => {
    if (cep.get().length === 8) {
      getAddressByCep(cep.get()).then((data) => {
        if (data.erro) {
          notify({
            message: "Dados não encontrados para esse CEP.",
            type: "error",
          });
        } else {
          street.set(data.logradouro);
          neighborhood.set(data.bairro);
          city.set(data.localidade);
          state.set(data.estado);
        }
      });
    }

    if (!cep.get().length) {
      street.set("");
      neighborhood.set("");
      city.set("");
      state.set("");
    }
  }, [cep.get()]);

  return (
    <>
      <Typography sx={{ width: "100%" }} variant="h6" fontWeight={"bold"}>
        Endereço
      </Typography>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputMask
          mask={`99999-999`}
          maskChar="_"
          value={cep.get()}
          required
          onChange={(event: { target: { value: string } }) =>
            cep.set(removeMask(event.target.value))
          }
        >
          <InputField label="CEP" required />
        </InputMask>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Rua"
          value={street.get()}
          onChange={(value: { target: { value: string } }) =>
            street.set(value.target.value)
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Número"
          value={number.get()}
          onChange={(value: { target: { value: string } }) =>
            number.set(value.target.value)
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          value={complement.get()}
          label="Complemento"
          onChange={(value: { target: { value: string } }) =>
            complement.set(value.target.value)
          }
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          value={neighborhood.get()}
          label="Bairro"
          onChange={(value: { target: { value: string } }) =>
            neighborhood.set(value.target.value)
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          value={city.get()}
          label="Cidade"
          onChange={(value: { target: { value: string } }) =>
            city.set(value.target.value)
          }
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          value={state.get()}
          label="Estado"
          onChange={(value: { target: { value: string } }) =>
            state.set(value.target.value)
          }
          required
        />
      </Grid>
    </>
  );
}
