import Grid from '@mui/material/Grid2';
import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import TitleLayout from '../../../core/Layout/TitleLayout';
import React, { useEffect, useState } from 'react';
import { getTrackById, listAllTracker } from '../services';
import { useSocket } from '../../../core/Contexts/SocketContext';
import { DataGrid, GridRowsProp, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { setPlateMask } from '../../../utils/PlateUtil';
import dayjs from 'dayjs';
import PlateFieldForm from '../../../components/Form/components/Fields/PlateFieldForm';
import { useForm } from 'react-hook-form';
import SecondaryResponsiveButton from '../../../components/Buttons/SecondaryResponsiveButton';
import PrimaryResponsiveButton from '../../../components/Buttons/PrimaryResponsiveButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import MoreInfoDataGridModal from '../components/DataGrid/MoreInfoDataGridModal';
import IconButton  from '@mui/material/IconButton';
import LabelLastUpdadeTracks from '../components/Card/LabelLastUpdadeTracks';
import { useAuthetication } from '../../../core/Contexts/AutheticationContext';
import EditTrackModal from '../components/DataGrid/EditTrackModal';
import EditIcon from '@mui/icons-material/Edit';
import GridLabel from '../components/DataGrid/GridLabel';
import SplashFeedback from '../../../core/Feedbacks/SplashFeedback';
import notify from '../../../utils/notify';
import SelectFieldDataGrid from '../components/DataGrid/SelectFieldDataGrid';
import LastUpdateFieldDataGrid from '../components/DataGrid/LastUpdateFieldDataGrid';
import SimpleInputFieldForm from '../../../components/Form/components/Fields/SimpleInputFieldForm';

export function GridAction ({hasClicked = ()=>{}, hasClickedEdit=()=>{}}) {
  const { checkIsIntegrador, checkIsAdmin } = useAuthetication();
  const isIntegrador: boolean = checkIsIntegrador()
  const isAdmin: boolean = checkIsAdmin()
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} sx={{height:'100%'}}>
      <Tooltip title="Mais Informação">
        <IconButton
            aria-label="Mais infomrações"
            onClick={hasClicked}
            
          >
          <RemoveRedEyeIcon />
        </IconButton>  
      </Tooltip>
      {(isIntegrador || isAdmin) && (

        <Tooltip title="Editar Rastreador">
        <IconButton
            aria-label="Editar Rastreador"
            onClick={hasClickedEdit}
            
            >
          <EditIcon />
        </IconButton>  
      </Tooltip>
          )} 
    </Grid>
  )
} 

export default function TracksListPage() {
  const {  checkIsIntegradorOperador } = useAuthetication();

  const [tracks, setTracks] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] =useState<boolean>(true);
  const columns: GridColDef[] = [
    { field: 'trackCode', headerName: 'Código', width: 200 },
    { field: 'plate', headerName: 'Placa', width: 200 },
    { 
      field: 'status', 
      headerName: 'Situação', 
      width: 200,
      renderCell: (params) => {
        return getStringStatus(params.row?.status);
      } 
    },
    { 
      field: 'created_at', 
      headerName: 'Data de Registro', 
      width: 250,
      renderCell: (params) => {
        return dayjs(params.row?.created_at).format("DD/MM/YYYY HH:mm:ss");
      }  
    },
    { 
      field: 'recentUpdate', 
      headerName: 'Última Atualização', 
      width: 250,
      renderCell: (params) => {
          return <LabelLastUpdadeTracks trackday={params.row?.recentUpdate} />;
      }  
    },
    { 
      field: 'action', 
      headerName: '', 
      width: 100,
      renderCell: (params) => {
        return <GridAction hasClicked={()=>loadOneById(params.row.id ,'see')} hasClickedEdit={()=>loadOneById(params.row.id ,'edit')}/>
      }  
    },
  ]
  const {socket } = useSocket()
  const pageSizeOptions = [5, 15,30,50, 100];
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(pageSizeOptions[1]);
  const [filters, setFilters] = useState<any>(null);
  const [sort, setSort] = useState<GridSortModel>([]);
  const [rowCount, setRowCount] = useState(0);
  const [moreTrackInfo, setMoreTrackInfo] = useState<any>(null);
  const [trackToEdit, setTrackToEdit] = useState<any>(null);
  const [isSarchingData, setIsSarchingData] = React.useState<boolean>(false);
  const selectStatus: Array<{label: string, value: string}> = [
    {label:'Nenhuma', value:'-1'},
    {label:'Ativado', value:'activated'},
    {label:'Desativado', value:'desactivated'}
  ]
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({ mode: "all" });


  const getStringStatus = (statusText: any) => {      
    return <span style={{fontWeight: 600, color: statusText === 'activated'? 'green': 'orange'}}>{statusText === 'activated'? 'Ativado': 'Desativado'}</span>
  }


  const getAllTrack = async (page:number, limit:number, sort:any, filter:any) => {
    setIsLoading(true)
    const response = await listAllTracker(page, limit, sort, filter)
    if(!response.hasError){
      const listTrack = response.response?.data?.data;
      setRowCount(response.response?.data?.rowsCount)
      const rows: GridRowsProp = listTrack.map((p:any)=> {
        return {
          ...p,
          plate: setPlateMask(p.plate),
        }
      })
      setTracks(rows as never)
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem track')
    }
  } 

  const updateTrack = (track: any) => {
    if(tracks.length > 0){
      const updatedList = tracks.map(trackList => {
        if(trackList.trackCode === track.code){
          return {
            ...trackList,
            recentUpdate: track.time
          };
        }else{
          return trackList
        }
      })
      setTracks(updatedList)
    }
  }

  const submitForm = async (body: any) => {
    let filtersForm = {}
    if(body.plate  !== undefined && body.plate  !== ''){
      filtersForm = {...filtersForm, plate: body.plate.replace('-','') }
    }
    if(body.status  !== undefined && body.status  !== '' && body.status  !== '-1'){
      filtersForm = {...filtersForm, status: body.status }
    }
    if(body.trackCode  !== undefined && body.trackCode  !== ''){
      filtersForm = {...filtersForm, trackCode: body.trackCode }
    }
    if(body.recentUpdate  !== undefined && body.recentUpdate  !== '' && body.recentUpdate.length > 0){
      filtersForm = {...filtersForm, recentUpdate: body.recentUpdate }
    }
    //const array = body.filter( (filter:any) =>( filter !== undefined && filter !== ''))
    console.log(filtersForm, 'array')
    setFilters(filtersForm)
   
  };

  const clearFileds = () => {
    setValue('plate', '')
    setValue('status', '-1')
    setValue('trackCode', '')
    setValue('recentUpdate', [])

    getAllTrack(page,limit, sort, {})
  }

    const loadOneById = async (id: number, acao: 'edit'| 'see') => {
      setIsSarchingData(true)
      await getTrackById(id)
        .then(({response, hasError}) => {
          if(!hasError){
            if(acao === 'see'){
              setMoreTrackInfo( response?.data)
            }
            else if(acao === 'edit'){
              setTrackToEdit( response?.data)
            }
          }
         
          setIsSarchingData(false)
        })
        .catch(() => {
          notify({ message: `Não foi possível carregar as informações.`, type: "error" });
          setIsSarchingData(false)
        })
        //.finally(() =>);
    };

    const filterByLastUpdate = (lastUpdateFilter: Array<any>) =>{
      if(filters !== null ){
        filters['recentUpdate'] = lastUpdateFilter
        setFilters(filters)
      }else{
        setFilters({recentUpdate:lastUpdateFilter})
      }
      console.log(filters, lastUpdateFilter,'filters')
  
    }
  

  useEffect( ()=> {
    
      getAllTrack(page,limit, sort, filters)

  }, [page,limit, filters])



 useEffect(()=> {
  if(socket !== null && !checkIsIntegradorOperador()) {
    socket.on("TRACKER_LOCATION", updateTrack);
  }

  return ()=> {
    if (socket !== null )
      socket.off("TRACKER_LOCATION", updateTrack);
  }
}, [socket,tracks])

  return (
    <MainLayout>
      <TitleLayout>
        Rastreadores
      </TitleLayout>
      <ContainerLayout minHeight="auto">
        <Grid container component="form" sx={{paddingBottom: 2, width: '100%'}} spacing={2} onSubmit={handleSubmit(submitForm)}>
        <Grid size={{xs:12, md:2}} >
            <SimpleInputFieldForm
              register={register}
              control={control}
              name='trackCode'
              value={getValues('trackCode')}
              setValue={setValue}
              id='Código'
              label='Código'
              //required={true}
              //disabled={isSearch}
            />
          </Grid>
          <Grid size={{xs:12, md:2}} >
            <PlateFieldForm
              register={register}
              control={control}
              name='plate'
              value={getValues('plate')}
              setValue={setValue}
              id='plate'
              //required={true}
              //disabled={isSearch}
            />
          </Grid>
          <Grid size={{xs:12, md:2}} >
            <SelectFieldDataGrid 
              register={register}
              control={control}
              value={getValues('status') || '-1'}
              setValue={setValue}
              name="status"
              data={selectStatus}
              label="Situação"
              />
          </Grid>
          <Grid size={{xs:12, md:3}} >
            <LastUpdateFieldDataGrid
              register={register}
              control={control}
              value={getValues('status')}
              setValue={setValue}
              name="recentUpdate"
             
              
              />
          </Grid>

          
          {/* <Grid size={{xs:12, md:3}} >
            <SimpleInputFieldForm
              label="Chave de Rastreamento"
              name="keyword"
              register={register}
              control={control}
              messageError={"O campo não pode ser vazio"}
              setValue={setValue}
              id='keyword'
              value={getValues('keyword')}
              //required={true}
              // disabled={isSearch}
              
              /> 
          </Grid>
            */}
          <Grid size={{xs:12, md:3}} >
            <Grid container spacing={2} sx={{ paddingTop: 0.5  }} alignItems={'center'} >
        
              <SecondaryResponsiveButton onClick={clearFileds}>
                <DeleteIcon/>Limpar
              </SecondaryResponsiveButton>
          
              <PrimaryResponsiveButton  type="submit"> 
                <SearchIcon />Buscar
              </PrimaryResponsiveButton>
          </Grid>
        </Grid> 
        </Grid>
        
           
        <GridLabel/>
       
          <Grid container >
            <DataGrid
              autoHeight={true}
              rows={tracks} 
              columns={columns} 
              rowCount={rowCount}
              loading={isLoading}
              pagination={true}
              paginationMode={'server'}
              filterMode={'server'}
              //sortingMode={'server'}
              pageSizeOptions={pageSizeOptions}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: limit,
                  },
                },
              }}
              onPaginationModelChange={({page, pageSize}) => {
                setPage(page);
                setLimit(pageSize);
               }}
               onSortModelChange={onSortChange => setSort(onSortChange)} 
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({from,to,count}) =>{
                      return `${from}-${to} de ${count}`
                    },
                  },
                  noRowsLabel: 'Rastreador(es) não encontrado',
              }}
              sx={{
                border: 0,
                //minHeight: '12px',
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
           
              slotProps={{
                pagination: {
                  labelRowsPerPage: "Rastreadores por página",
                },
                
              }}/>
          </Grid>
        {/* ):(
          <Grid container alignItems={"center"} justifyContent={"center"} sx={{height: '80vh'}}>
            <Typography align="center" sx={{ color: '#a63939', mb: 1 , fontSize: '1rem'}}>
              Sem Rastreadores
            </Typography>
          </Grid>
        )} */}
        <MoreInfoDataGridModal 
          track={moreTrackInfo} 
          onHandleClose={()=>setMoreTrackInfo(null)}
        />
        <EditTrackModal trackToEdit={trackToEdit} onCloseModal={()=>{setTrackToEdit(null)}}/>
        <SplashFeedback open={isSarchingData} size={100}  />
      </ContainerLayout>
      
    </MainLayout>
 
 
  );
}
//863829075937217