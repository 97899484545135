import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import MainLayout from '../core/Layout/MainLayout';
import { Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useAuthetication } from '../core/Contexts/AutheticationContext';

function HomePage() {
  const navigate = useNavigate();
  const {isAuthenticated, userGroup} = useAuthetication()

  useEffect(()=>{
    if(userGroup.length > 0 ){
        if(userGroup.includes('administrador') ){
          navigate("/agendamento");
        }else if(userGroup.includes('integrador')){
          navigate("/cadastro/rastreador");
        }else if(userGroup.includes('teleatendente')){
          navigate("/ativar/rastreamento");
        }
        else if(userGroup.includes('cidadão')){
          navigate("/rastreadores");
        }
        else{
          navigate("/acesso-restrito");
        }
    }
 }, [userGroup])


  return (
    <MainLayout>
       {isAuthenticated && (
        <Grid container>
         <Typography variant="h3" component={"p"}>
           Bem vindos
         </Typography> 
        </Grid>
       )}
     
    </MainLayout>
 
 
  );
}

export default HomePage;
