import React, { useEffect } from 'react';
import { RouterApp } from './core/Router';
import {RouterProvider} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import ThemeApp from './core/Theme';
import { useAuth} from 'react-oidc-context';
import SocketContextProvider from './core/Contexts/SocketContext';
import AutheticationContextProvider from './core/Contexts/AutheticationContext';

function App() {
  const auth = useAuth();
  //console.log( process.env.REACT_APP_MAIN_URL_API,' process.env.REACT_APP_BASE_URL')
  //console.log('Keycloak', auth)
  if (auth.error) {
    console.log('Erro Keycloak', auth.error)
  }

  return (
  <ThemeProvider theme={ThemeApp}>
    <AutheticationContextProvider>
      <SocketContextProvider>
        <RouterProvider router={RouterApp} />
      </SocketContextProvider>
    </AutheticationContextProvider>
  </ThemeProvider>
  );
}

export default App;
