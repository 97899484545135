import React, { useState } from "react";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import HiddenKeyWordTracksCard from './HiddenKeyWordTracksCard'
import LabelLastUpdadeTracks from "./LabelLastUpdadeTracks";

type CardTracksProps = {
    trackCode: string;
    status: string;
    plate: string;
    trackday: string;
    statusCode: boolean;
    keyword: string;
    isTrack: boolean;
}

export default function CardTracks({ 
    trackCode= '', 
    status = '', 
    plate ='', 
    trackday= '', 
    keyword = '',
}: any) {
    const [showKeyWord, setShowKeyWord] = useState<boolean>(false)

    const onClickKeyWord = () => {
        setShowKeyWord(!showKeyWord)
    }

    const getStringStatus = (statusText: string) => {
        return <span style={{fontWeight: 600, color: statusText === 'activated'? 'green': 'orange'}}>{statusText === 'activated'? 'Ativado': 'Desativado'}</span>
    }

    return (
        <Card elevation={0} sx={{border: '2px solid #eaeaea', borderRadius:'8px'}}> 
       
            <CardContent sx={{pb: 1 , position: 'relative', backgroundColor:'#efefef'}}>
               
                <Grid container direction={'column'}>
                    <Typography component="p" gutterBottom sx={{ color: 'text.secondary', fontSize: 16, display: 'block' }}>
                        <b>Código do Rastreador: </b>{trackCode}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 1, fontSize: '1rem'  }}><b>Situação: </b>{getStringStatus(status)}</Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 1 , fontSize: '1rem'}}><b>Placa: </b>{plate}</Typography>
                    <Grid size={{xs: 12}}>
                        <Typography sx={{ color: 'text.secondary', mb: 1 , fontSize: '1rem', whiteSpace: 'nowrap'}}>
                            <b>Última Atualização: </b>
                          
                            <LabelLastUpdadeTracks trackday={trackday} />
                            
                        </Typography>
                      
                   </Grid>
                        <Typography sx={{ color: 'text.secondary', mb: 1 , fontSize: '1rem', display: 'flex', alignItems: 'center'}}>
                            <b>Chave de rastreamento: </b>
                               
                            <Tooltip title={ !showKeyWord? 'Ver chave de rastreamento':' Ocultar chave de rastreamento'}>
                                {!showKeyWord? <><HiddenKeyWordTracksCard /> <VisibilityIcon onClick={onClickKeyWord}/></>:<VisibilityOffIcon onClick={onClickKeyWord}/>} 
                            </Tooltip>        
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 1 , fontSize: '1rem'}}>   
                         {showKeyWord? keyword: ''}
                        </Typography>
   
                </Grid>
               
            </CardContent>
        </Card>
    )
}
