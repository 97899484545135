import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Grid from "@mui/material/Grid2";
import {  getCitizenById, updateCitizen} from "../../services";
import notify from "../../../../utils/notify";
import { useForm } from "react-hook-form";
import SimpleInputFieldForm from "../../../../components/Form/components/Fields/SimpleInputFieldForm";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PhoneFieldForm from "../../../../components/Form/components/Fields/PhoneFieldForm";
import EmailFieldForm from "../../../../components/Form/components/Fields/EmailFieldForm";
import DataPickerFieldForm from "../../../../components/Form/components/Fields/DataPickerFieldForm";
import CEPFieldForm from "../../../../components/Form/components/Fields/CEPFieldForm";


export interface EditModalProps {
  readonly citizenToEdit: null;
  onCloseModal: ()=> void
}

export default function EditModalCrud({ citizenToEdit = null, onCloseModal = ()=>{} }: EditModalProps) {
  
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState<any>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [editCitizen, setEditCitizen] = useState<any>(null);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setFocus,
    getValues,
    formState: {  isValid },
    
  } = useForm({ mode: "all" });
  const [address, setAddress] = useState<any>(null)


  const submitForm = async () => {
    setError(false);
    setMessageError("");
    setProcessing(true);
    const body = getValues()
    console.log(body, 'body', isValid)
    //if(isValid){
      // body['userId'] = track?.userId
      body['id'] = editCitizen?.id
      const { hasError, data, error } = await updateCitizen(body);
      if (!hasError) {
        notify({ message: `Cidadão atualizado com sucesso`, type: "success" });
        onCloseModal()
        //open.set(false);
      } else {
        setError(true);
        setMessageError(error);
  
        if (error?.invalid_params && error.invalid_params.length) {
          error.invalid_params?.map((item: { reason: string }) =>
            notify({
              message: `${item.reason}`,
              type: "error",
            })
          );
        } else if (error?.details.length) {
          notify({
            message: `${error.details}`,
            type: "error",
          });
        } else {
          notify({
            message: `Algo deu errado, tente novamento mais tarde.`,
            type: "error",
          });
        }
      }
  
   // }
   
    setProcessing(false);
   
  };

  const getAddressByViaCEPApi = async (cep: string) => {
    try {

      const fetchResponse = await fetch(`http://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
      const response = await fetchResponse.json()

      if(response?.error !== undefined){
        //const infoApi = response
        setAddress(response)
        setValue('street', response.logradouro)
        setValue('city', response.localidade)
        setValue('neighborhood', response.bairro)
        setValue('state', response.estado)
      }else{
        setValue('street', '')
        setValue('city', '')
        setValue('neighborhood','')
        setValue('state','')
        notify({
          message: `O cep é inválido.`,
          type: "error",
        });
      }
    } catch (e){
      console.log('Erro ao consultar via CEP:', e)
      notify({
        message: `Erro ao consultar via CEP.`,
        type: "error",
      });
    } 
   } 


  useEffect(() => {
    //if (citizenToEdit !== undefined && citizenToEdit !== null) {
      setEditCitizen(citizenToEdit);
    //}

  }, [citizenToEdit]);

  return (
    <Dialog
      open={Boolean(citizenToEdit)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      keepMounted={false}
    >
      <DialogTitle id="alert-dialog-title">
        Editar Cidadãos
      </DialogTitle>
      <DialogContent>
      <Grid container spacing={2} component="form" sx={{pt: 2}} 
      //onSubmit={handleSubmit(submitForm)}
      >
        <Grid size={{ xs: 12, sm: 6}}>
          <SimpleInputFieldForm 
            name='name' 
            label='Nome' 
            required={true}  
            value= {editCitizen?.name} 
            id='name'
            register={register} 
            control={control}
            setValue={setValue}
          />
        </Grid>
       
        <Grid size={{xs:12 , sm:6}}>
          <PhoneFieldForm 
            name='phone' 
            label='Telefone' 
            required={true}  
            value= {editCitizen?.phone} 
            id='phone'
            register={register} 
            control={control}
            setValue={setValue}
          />
        </Grid>
        

        <Grid size={{xs:12 , sm:6}}>
          <EmailFieldForm 
            name='email' 
            label='Email' 
            required={true}  
            value= {editCitizen?.email} 
            id='email'
            register={register} 
            control={control}
            setValue={setValue}
          />
        </Grid>

        <Grid size={{xs:12 , sm:6}}>
          <DataPickerFieldForm 
            name='birthDate' 
            label='Data de Nascimento' 
            required={true}  
            value= {editCitizen?.birthDate} 
            id='birthDate'
            register={register} 
            control={control}
            setValue={setValue}
          />
        </Grid>
        <Grid size={{xs:12 , sm:6}}>
          <CEPFieldForm 
            name='zip_code' 
            required={true}  
            value= {editCitizen?.zip_code} 
            register={register} 
            control={control}
            setValue={setValue}
            onBlurCEP={getAddressByViaCEPApi}
          />
        </Grid>
       
        <Grid size={{xs:12, sm:10}}>
       <SimpleInputFieldForm 
        name='street' 
        label='Endereço' 
        required={true}  
        messageError='A Razão Social é obrigatório' 
        value= {editCitizen?.street} 
        id='street_id'
        register={register} 
        control={control}
        setValue={setValue}
      /> 
    </Grid>
    <Grid size={{xs:12, sm:2}}>
      <SimpleInputFieldForm 
        name='number' 
        label='Número'   
       value= {editCitizen?.number} 
   
        id='number_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid> 
    

   <Grid size={{xs:12, sm:6}}>
      <SimpleInputFieldForm 
        name='neighborhood' 
        label='Bairro' 
        messageError='Nome é obrigatório' 
      
        value= {editCitizen?.neighborhood} 
  
        id='neighborhood_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    <Grid size={{xs:12, sm:6}}>
      <SimpleInputFieldForm 
        name='city' 
        label='Cidade' 
        messageError='Cidade é obrigatório' 
       
        value= {editCitizen?.city} 
 
        id='city_id'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    <Grid size={{xs:12, sm:6}}>
      <SimpleInputFieldForm 
        name='state' 
        label='Estado' 
        messageError='Estado é obrigatório' 
        value= {editCitizen?.state} 

        id='state_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
        
        
   

        
        <Grid container size={{xs:12}} justifyContent={"flex-end"}>
          <SecondaryButton onClick={onCloseModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton onClick={submitForm}>
            Editar
          </PrimaryButton>
        </Grid>
        
        </Grid>
       
      </DialogContent>

    </Dialog>
  );
}
