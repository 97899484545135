import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import { useAuthetication } from "../../Contexts/AutheticationContext";
import MenuIntegrador from "./MenuIntegrador";
import MenuCidadão from "./MenuCidadão";
import MenuTeleatendende from "./MenuTeleatendende";
import MenuAdmin from "./MenuAdmin";

export default function Menu({hiddenMenuLabel=false, onMenuClick = ()=>{}}) {
  const {userGroup,checkIsAdmin, checkIsIntegrador} = useAuthetication()   
  const handleMenuClick = (menuItem: string) => {

    onMenuClick()
  };


  const renderMenuByGroup = useCallback(() => {
    if (userGroup.length > 0) {
      if (checkIsAdmin()){
        return(<MenuAdmin hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      } else if (checkIsIntegrador())  {
        return( 
          <MenuIntegrador  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick}/>
        )
      }else if (userGroup.includes('teleatendente')){
        return( <MenuTeleatendende hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }
      else if ( userGroup.includes('cidadão')) {
        return( <MenuCidadão  hiddenMenuLabel={hiddenMenuLabel} onMenuClick={handleMenuClick} />)
      }
    }
  }, [userGroup, hiddenMenuLabel]);

  

  return (
    <List sx={{width:'100%'}}>
        {renderMenuByGroup()}
    </List>
  );
}
