import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { State, useHookstate } from "@hookstate/core";
import { ICitizen } from "../SearchCitizenTab";
import {
  CircularProgress,
} from "@mui/material";
import EditCitizenModal from "../EditCitizenModal";
import { getCitizenById } from "../../services";
import notify from "../../../../utils/notify";
import CitizenSummary from "../CitizenSummary";
import VehicleSummary from "../VehicleSummary";
import TrackSummary from "../TrackSummary";

interface IKeywordRegisterTab {
  readonly plate: string;
  readonly trackId: string;
  readonly trackChip: string;
  readonly value: State<number>;
  readonly citizen: string | null;
  readonly confirmAction: () => void;
  readonly brand: string | null;
  readonly image: string | ArrayBuffer | ImageData | null;
  readonly loadingButton: boolean;
}

export default function SummaryTab({
  citizen,
  value,
  plate,
  trackId,
  brand,
  trackChip,
  image,
  loadingButton,
  confirmAction,
}: IKeywordRegisterTab) {
  const openModalEdit = useHookstate(false);
  const citizenDetails = useHookstate<ICitizen | null>(null);
  const forceRefresh = useHookstate(false);
  const loading = useHookstate(false);

  const loadData = (id: string) => {
    loading.set(true);
    getCitizenById(id)
      .then((data: any) => {
        citizenDetails.set(data);
      })
      .catch(() => {
        notify({ message: `Cidadão não encontrado.`, type: "error" });
      })
      .finally(() => loading.set(false));
  };

  useEffect(() => {
    if (citizen !== undefined && citizen !== null) {
      loadData(citizen);
    }
  }, [citizen, forceRefresh.get()]);

  return (
    <Grid container spacing={2} sx={{ width: "100%", marginX: 2 }}>
      {loading.get() ? (
        <Grid container size={12} display={'flex'} sx={{justifyContent: 'center'}}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <CitizenSummary
            citizenDetails={citizenDetails.get({ noproxy: true })}
            openModalEdit={openModalEdit}
          />
          <VehicleSummary plate={plate} />
          <TrackSummary
            brand={brand}
            image={image}
            trackChip={trackChip}
            trackId={trackId}
          />
        </>
      )}

      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton onConfirmClick={confirmAction} loading={loadingButton} />
        </Grid>
      </Grid>
      <EditCitizenModal
        open={openModalEdit}
        id={citizen ?? ""}
        citizenDetails={citizenDetails.get({noproxy: true})}
        forceRefresh={forceRefresh}
      />
    </Grid>
  );
}
