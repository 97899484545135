import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface VehicleSummary {
  readonly plate: string;
}

export default function VehicleSummary({ plate }: VehicleSummary) {
  return (
    <>
      <Grid size={12}>
        <Typography variant="h6" fontWeight={"bold"}>
          Dados Veículo
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <Typography>
          <b>Placa do Veículo:</b> {plate}
        </Typography>
      </Grid>
    </>
  );
}
