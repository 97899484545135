import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ImmutableObject, State, useHookstate } from "@hookstate/core";
import Grid from "@mui/material/Grid2";
import { editCitizen } from "../../services";
import notify from "../../../../utils/notify";
import { ICitizen } from "../SearchCitizenTab";
import { Box } from "@mui/material";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import AddressDataRegister from "../../../Citizen/components/AddressDataRegister";
import ApplicationDataRegister from "../../../Citizen/components/ApplicationDataRegister";
import PersonalDataRegister from "../../../Citizen/components/PersonalDataRegister";
import { useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";

export interface EditCitizenModalProps {
  readonly open: State<boolean>;
  readonly id: string;
  readonly forceRefresh: State<boolean>;
  readonly citizenDetails: ImmutableObject<ICitizen> | null;
}

export default function EditCitizenModal({
  open,
  citizenDetails,
  id,
  forceRefresh,
}: EditCitizenModalProps) {
  const loading = useHookstate<boolean>(false);

  const cep = useHookstate("");
  const state = useHookstate("");
  const fullName = useHookstate("");
  const cpf = useHookstate("");
  const email = useHookstate("");
  const phone = useHookstate("");
  const birthDate = useHookstate<Dayjs | null>(null);
  const street = useHookstate("");
  const number = useHookstate("");
  const complement = useHookstate("");
  const neighborhood = useHookstate("");
  const city = useHookstate("");
  const principalPlatform = useHookstate<number | null>(null);
  const companyBranch = useHookstate<number | null>(null);

  const { handleSubmit } = useForm({ mode: "all" });

  useEffect(() => {
    if (citizenDetails) {
      cep.set(citizenDetails.zip_code);
      state.set(citizenDetails.state);
      fullName.set(citizenDetails.name);
      cpf.set(citizenDetails.cpf);
      email.set(citizenDetails.email);
      phone.set(citizenDetails.phone);
      birthDate.set(dayjs(citizenDetails.birthDate));
      street.set(citizenDetails.street);
      number.set(citizenDetails.number);
      complement.set(citizenDetails.complement);
      neighborhood.set(citizenDetails.neighborhood);
      city.set(citizenDetails.city);
      principalPlatform.set(citizenDetails.vehiclePurposeId);
    }
  }, [citizenDetails]);

  const getFormData = () => ({
    full_name: fullName.get(),
    cpf: cpf.get(),
    phone: phone.get(),
    email: email.get(),
    birth_date: birthDate.get(),
    zip_code: cep.get(),
    street: street.get(),
    number: number.get(),
    complement: complement.get(),
    neighborhood: neighborhood.get(),
    city: city.get(),
    state: state.get(),
    vehiclePurposeId: principalPlatform.get(),
    companyBranchId: companyBranch.get(),
  });

  const handleSuccess = () => {
    notify({ message: `Cidadão atualizado com sucesso`, type: "success" });
    open.set(false);
  };

  const handleFormErrors = (error: any) => {
    if (error?.invalid_params?.length) {
      error.invalid_params.forEach(({ reason }: { reason: string }) => {
        notify({ message: reason, type: "error" });
      });
    } else if (error?.details?.length) {
      notify({ message: error.details, type: "error" });
    } else {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    }
  };

  const submitForm = async () => {
    try {
      loading.set(true);

      const body = getFormData();
      const { hasError, data, error } = await editCitizen(id, body);

      if (hasError) {
        handleFormErrors(error);
      } else {
        handleSuccess();
      }
    } catch (err) {
      notify({
        message: `Algo deu errado, tente novamente mais tarde.`,
        type: "error",
      });
    } finally {
      loading.set(false);
      forceRefresh.set(!forceRefresh.get());
    }
  };

  return (
    <Dialog
      open={open.get()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">
        Editar informações do cidadão
      </DialogTitle>
      <DialogContent>
        <Box
          component={"form"}
          onSubmit={handleSubmit(submitForm)}
          sx={{ width: "100%" }}
        >
          <Grid container size={12} display={"flex"} spacing={2}>
            <PersonalDataRegister
              birthDate={birthDate}
              cpf={cpf}
              email={email}
              fullName={fullName}
              phone={phone}
            />
            <AddressDataRegister
              cep={cep}
              city={city}
              complement={complement}
              neighborhood={neighborhood}
              number={number}
              state={state}
              street={street}
            />
            <ApplicationDataRegister
              principalPlatform={principalPlatform}
              companyBranch={companyBranch}
            />
          </Grid>

          <Grid
            container
            size={12}
            sx={{ gap: 1 }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Grid
              size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
              display={"flex"}
              sx={{ justifyContent: "flex-end" }}
            >
              <CancelButton onCancelClick={() => open.set(false)} />
            </Grid>
            <Grid
              size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
              display={"flex"}
              sx={{ justifyContent: "flex-end" }}
            >
              <ConfirmButton type="submit" loading={loading.get()} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
