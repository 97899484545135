import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useForm } from 'react-hook-form';
import SimpleInputFieldForm from '../../../../components/Form/components/Fields/SimpleInputFieldForm';
import { useAccreditedStep } from '../../contexts/AccreditedStepContext';
import CEPFieldForm from '../../../../components/Form/components/Fields/CEPFieldForm';
import ControlStepForm from './Steps/ControlStepForm';
import { useAccreditedForm } from '../../contexts/AccreditedFormContext';


export default function AddressAccreditedForm() {
  const {handleNext} = useAccreditedStep();
  const {mergeFieldsInState, fields} = useAccreditedForm()
  const [address, setAddress] = useState<any>(null)

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {isValid },
  } = useForm({ mode: "all" });

  const submitForm = async (event:any) => {
    try {
      if (isValid){
        handleNext()
        mergeFieldsInState(event)
      }
      
    } catch (e) {
      console.log(e, 'submit')
    }
    

   }

   const getAddressByViaCEPApi = async (cep: string) => {
    try {

      const response = await fetch(`http://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
      if(response){
        const infoApi = await response.json()
        setAddress(infoApi)
      }
    } catch (e){
      console.log('Erro ao consultar via CEP:', e)
    } 
   }  


  return (
    <Grid container component="form" sx={{padding: 2}} spacing={2} onSubmit={handleSubmit(submitForm)}>
      <Grid size={{xs:12, sm: 6, md:3, lg:2}}>
        <CEPFieldForm 
          name='zip_code' 
          required={true}  
          value= {fields?.zip_code} 
          id='cep_input'
          register={register} 
          control={control}
          setValue={setValue}
          onBlurCEP={getAddressByViaCEPApi}

        />
      </Grid>
               
    <Grid size={{xs:12, sm: 6, md:6, lg:6, xl:5}}>
      <SimpleInputFieldForm 
        name='street' 
        label='Endereço' 
        required={true}  
        messageError='A Razão Social é obrigatório' 
        value= {fields?.street? fields?.street: address?.logradouro} 
        id='street_id'
        register={register} 
        control={control}
        setValue={setValue}

      />
    </Grid>
    <Grid size={{xs:12, sm: 4, md:1}}>
      <SimpleInputFieldForm 
        name='number' 
        label='Número'   
        value= {fields?.number}  
        id='neighborhood_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    <Grid size={{xs:12,  sm: 6, md:4, lg:3}}>
      <SimpleInputFieldForm 
        name='neighborhood' 
        label='Bairro' 
        messageError='Nome é obrigatório' 
        value= {fields?.neighborhood? fields?.neighborhood: address?.bairro} 
        id='neighborhood_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
       <Grid size={{xs:12,  sm: 6, md:3, lg:2}}>
      <SimpleInputFieldForm 
        name='state' 
        label='Estado' 
        messageError='Estado é obrigatório' 
        value= {fields?.state? fields?.state: address?.estado} 
        id='state_input'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    
 
    <Grid size={{xs:12,  sm: 6, md:3, lg:2}}>
      <SimpleInputFieldForm 
        name='city' 
        label='Cidade' 
        messageError='Cidade é obrigatório' 
        value= {fields?.city? fields?.city: address?.localidade} 
        id='city_id'
        register={register} 
        control={control}
        setValue={setValue}
        required={true}  

      />
    </Grid>
    <Grid size={{xs:12, sm: 6, md:6, lg:4}}>
      <SimpleInputFieldForm 
        name='complement' 
        label='Complemento'   
        value= {fields?.complement} 
        id='complement_input'
        register={register} 
        control={control}
        setValue={setValue}

      />
    </Grid>
 
    <ControlStepForm canMoveForward={isValid}/>
    
    </Grid>
           

  );
}

