import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { State } from "@hookstate/core";

import Grid from "@mui/material/Grid2";
import { editCitizen, getTrackById, updateTracker } from "../../services";
import notify from "../../../../utils/notify";
import FormCrud from "../../../../components/Form/components/FormCrud";
import { useForm } from "react-hook-form";
import SimpleInputFieldForm from "../../../../components/Form/components/Fields/SimpleInputFieldForm";
import PlateFieldForm from "../../../../components/Form/components/Fields/PlateFieldForm";
import FilterAutocompleteCitizen from "../../../../components/Filters/FilterAutocompleteCitizen";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import IconButton  from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import { sanitizerName } from "../../../../utils/StringsManipulationsUtil";
import SplashFeedback from "../../../../core/Feedbacks/SplashFeedback";
import GridDataDisplay from "../../../../components/DataDisplay/GridDataDisplay";


export interface EditTrackModalProps {
  readonly trackToEdit: any;
  onCloseModal: ()=> void
}

export default function EditTrackModal({ trackToEdit = null, onCloseModal = ()=>{} }: EditTrackModalProps) {
  
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState<any>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [track, setTrack] = useState<any>(null);
  const [editCitizen, setEditCitizen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);


  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {  isValid },
    
  } = useForm({ mode: "all" });

  const submitForm = async (body: any) => {
    setError(false);
    setMessageError("");
    setProcessing(true);
    if(isValid){
      if(user !== undefined && user !== null){
        body['userId'] = user?.id
      }
      body['id'] = track?.id
      const { hasError, data, error } = await updateTracker(body);
      if (!hasError) {
        notify({ message: `Rastreamento atualizado com sucesso`, type: "success" });
        onCloseModal()
        //open.set(false);
      } else {
        setError(true);
        setMessageError(error);
  
        if (error?.invalid_params && error.invalid_params.length) {
          error.invalid_params?.map((item: { reason: string }) =>
            notify({
              message: `${item.reason}`,
              type: "error",
            })
          );
        } else if (error?.details.length) {
          notify({
            message: `${error.details}`,
            type: "error",
          });
        } else {
          notify({
            message: `Algo deu errado, tente novamento mais tarde.`,
            type: "error",
          });
        }
      }
  
    }
   
    setProcessing(false);
   
  };



  useEffect(() => {
      setEditCitizen(false)
      setTrack(trackToEdit);
      setUser(trackToEdit?.userId)
  
  }, [trackToEdit]);

  return (
    <Dialog
      open={Boolean(trackToEdit)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Editar Rastreadores
      </DialogTitle>
      <DialogContent>
      <Grid container spacing={2} component="form" sx={{pt: 2}} onSubmit={handleSubmit(submitForm)}>
      {editCitizen?(
        <>
         <Grid size={{ xs: 11}}>
           <GridDataDisplay titulo='Cidadão' info={sanitizerName(user?.name)}/>
         </Grid>
        <Grid size={{ xs: 10}}>
          <Grid container spacing={1} sx={{width: '100%'}} wrap="nowrap">
            
              <FilterAutocompleteCitizen
                citizenId={track?.userId}
                onCitizenChange={(value) => {
                  setUser(value)
                }}
                error={error}
                required
                />
            <Grid size={{ xs: 2}}>  
              <Tooltip title="Cancelar Troca de Cidadão">
                <IconButton
                    aria-label="cancelar"
                    onClick={()=>setEditCitizen(false)}
                    
                    >
                  <DeleteIcon />
                </IconButton>  
              </Tooltip>
            </Grid>  
            </Grid>
          </Grid>  
          </>
          ):(

            <Grid size={{ xs: 10}} container wrap="nowrap">
          <SimpleInputFieldForm 
              name='citizien' 
              label='Cidadão' 
              required={true}  
              //messageError='O rastreador é obrigatório' 
              value= {sanitizerName(track?.user?.name)} 
              id='nome_fantasia'
              register={register} 
              control={control}
              setValue={setValue}
              disabled={true}
              />
                <Tooltip title="Trocar Cidadão">
                  <IconButton
                      aria-label="Editar Rastreador"
                      onClick={()=>setEditCitizen(true)}
                      
                      >
                    <EditIcon />
                  </IconButton>  
                </Tooltip>
          
        </Grid>
            )}
        
        <Grid size={{xs:12, md:6}}>
          <PlateFieldForm 
            name='plate' 
            label='Placa' 
            required={true}  
            messageError='A placa é obrigatória' 
            value= {track?.plate} 
            id='plate'
            register={register} 
            control={control}
            setValue={setValue}
            />
        </Grid>
        
        <Grid size={{xs:12, md:6}}>
          <SimpleInputFieldForm 
            name='trackCode' 
            label='Código do Rastreador' 
            required={true}  
            messageError='O rastreador é obrigatório' 
            value= {track?.trackCode} 
            id='nome_fantasia'
            register={register} 
            control={control}
            setValue={setValue}
            />
        </Grid>
        <Grid size={{xs:12, md:6}}>
          <SimpleInputFieldForm 
            name='trackChip' 
            label='Chip' 
            required={true}  
            messageError='O código do chip é obrigatório' 
            value= {track?.chipCode} 
            id='chipCode'
            register={register} 
            control={control}
            setValue={setValue}
            />
        </Grid>
        <Grid size={{xs:12, md:6}}>
          <SimpleInputFieldForm 
            name='brand' 
            label='Marca' 
           
            messageError='A marca é obrigatório' 
            value= {track?.brand} 
            id='brand'
            register={register} 
            control={control}
            setValue={setValue}
            />
        </Grid>
        <Grid container size={{xs:12}} justifyContent={"flex-end"}>
          <SecondaryButton onClick={onCloseModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">
            Editar
          </PrimaryButton>
        </Grid>
        
        </Grid>
        <SplashFeedback open={processing} size={100}  />

      </DialogContent>

    </Dialog>
  );
}
