import Grid from '@mui/material/Grid2';
import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import TitleLayout from '../../../core/Layout/TitleLayout';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import SecondaryResponsiveButton from '../../../components/Buttons/SecondaryResponsiveButton';
import PrimaryResponsiveButton from '../../../components/Buttons/PrimaryResponsiveButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
//import { useAuthetication } from '../../../core/Contexts/AutheticationContext';
import CPFFieldForm from '../../../components/Form/components/Fields/CPFFieldForm';
import { getCitizenById, listCitizenByGroup } from '../services';
import { setCPFMask } from '../../../utils/CPFUtil';
import { setPhoneMask } from '../../../utils/PhoneUtil';
import { sanitizerName } from '../../../utils/StringsManipulationsUtil';
import SimpleInputFieldForm from '../../../components/Form/components/Fields/SimpleInputFieldForm';
import GridActionCrud from '../components/Crud/GridActionCrud';
import EditModalCrud from '../components/Crud/EditModalCrud';
import { editCitizen } from '../../Tracks/services';
import SplashFeedback from '../../../core/Feedbacks/SplashFeedback';
import notify from '../../../utils/notify';
import { resetPasswordById } from '../../Users/services/UsersAuthService';

export default function CitizenListPage() {
  //const {  checkIsIntegradorOperador } = useAuthetication();

  const [data, setData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] =useState<boolean>(true);
  const columns: GridColDef[] = [
    { 
      field: 'cpf', 
      headerName: 'CPF', 
      width: 150,
      renderCell: (params) => {
        return setCPFMask(params.row.cpf);
      }   
    },
   
    { 
      field: 'name', 
      headerName: 'Nome', 
      width: 300,
      renderCell: (params) => {
        return sanitizerName(params.row.name);
      }   
    },
    { field: 'email', headerName: 'Email', width: 250 },
    { 
      field: 'phone', 
      headerName: 'Telefone', 
      width: 150,
      renderCell: (params) => {
        return setPhoneMask(params.row.phone);
      }   
      
    },
    { 
      field: 'birthDate', 
      headerName: 'Nascimento', 
      width: 150,
      renderCell: (params) => {
        return dayjs(params.row?.birthDate).format("DD/MM/YYYY");
      }  
    },
  
    { 
      field: 'action', 
      headerName: '', 
      width: 100,
      renderCell: (params) => {
        return <GridActionCrud 
          hasSeeMoreClicked={()=>setMoreInfo(params.row)} 
          hasEditClicked={()=>loadData(params.row.id)} 
          onResetPasswordClicked={()=>resetPassword(params.row.email)}
        />
      }  
    },
  ]
  const pageSizeOptions = [5, 15,30,50, 100];
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(pageSizeOptions[1]);
  const [filters, setFilters] = useState<any>(null);
  const [sort, setSort] = useState<GridSortModel>([]);
  const [rowCount, setRowCount] = useState(0);
  const [moreInfo, setMoreInfo] = useState<any>(null);
  const [selectedDataToEdit, setSelectedDataToEdit] = useState<any>(null);
  const [isSarchingData, setIsSarchingData] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({ mode: "all" });


  const getAll = async (page:number, limit:number, sort:any, filter:any) => {
    setIsLoading(true)
    const response = await listCitizenByGroup('cidadão', page, limit, sort, JSON.stringify(filter))
    if(!response.hasError){
      const data = response.data;
     
      setData(data as never)
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem Cidaãos')
    }
  } 


  const submitForm = async (body: any) => {
    if(body.cpf !== ''){
      setFilters({
        cpf: body.cpf,
        name: body.name
      })
    }
  };

  const loadData = async (id: string) => {
    setIsSarchingData(true)
    await getCitizenById(id)
      .then(({data, hasError}) => {
        const fildesValue =  data
      
        setSelectedDataToEdit(fildesValue)
      
        
      })
      .catch(() => {
        notify({ message: `Cidadão não encontrado.`, type: "error" });
       setIsSarchingData(false)
      })
      //.finally(() =>);
  };

  const clearFileds = () => {
    setValue('cpf', '')
    setValue('name', '')

    getAll(page,limit, sort, {})
  }

  const resetPassword = async (email: string) => {
    setIsSarchingData(true)
    const response = await resetPasswordById(email)
    if(!response.hasError){
      notify({ message: `Email com nova senha temporária enviado com sucesso.`, type: "success" });
      setIsSarchingData(false)
    }else{
      notify({ message: `Ocorreu erro ao tentar redefinir a senha.`, type: "error" });
      setIsSarchingData(false)
    }
  }

  useEffect( ()=> {
    
    getAll(page,limit, sort, filters)

  }, [page,limit, filters])



  useEffect(()=>{
    setIsSarchingData(false)
  }, [selectedDataToEdit])



  return (
    <MainLayout>
      <TitleLayout>
        Cidadãos
      </TitleLayout>
      <ContainerLayout minHeight="auto">
        <Grid container component="form" sx={{paddingBottom: 2, width: '100%'}} spacing={2} onSubmit={handleSubmit(submitForm)}>
          <Grid size={{xs:12, md:3}} >
            <CPFFieldForm
              register={register}
              control={control}
              name='cpf'
              value={getValues('cpf')}
              setValue={setValue}
              id='cpf'
              label="Buscar por CPF"
           
            />
          </Grid>
          <Grid size={{xs:12, md:3}} >
            <SimpleInputFieldForm
              label="Nome"
              name="name"
              register={register}
              control={control}
             
              setValue={setValue}
              id='name'
              value={getValues('name')}
            
              /> 
          </Grid>
           
          <Grid size={{xs:12, md:6}} >
            <Grid container spacing={2} sx={{ paddingTop: 0.5  }} alignItems={'center'} >
        
              <SecondaryResponsiveButton onClick={clearFileds}>
                <DeleteIcon/>Limpar
              </SecondaryResponsiveButton>
          
              <PrimaryResponsiveButton  type="submit"> 
                <SearchIcon />Buscar
              </PrimaryResponsiveButton>
          </Grid>
        </Grid> 
        </Grid>
        
       
          <Grid container >
            <DataGrid
              autoHeight={true}
              rows={data} 
              columns={columns} 
              rowCount={rowCount}
              loading={isLoading}
              pagination={true}
              paginationMode={'server'}
              filterMode={'server'}
              //sortingMode={'server'}
              pageSizeOptions={pageSizeOptions}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: limit,
                  },
                },
              }}
              onPaginationModelChange={({page, pageSize}) => {
                setPage(page);
                setLimit(pageSize);
               }}
               onSortModelChange={onSortChange => setSort(onSortChange)} 
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({ count }) =>{
                      return `mostrando ${data.length} registros de ${count}`
                    },
                  },
                  noRowsLabel: 'Cidadão(s) não encontrado',
              }}
              sx={{
                border: 0,
                //minHeight: '12px',
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                },
              }}
           
              slotProps={{
                pagination: {
                  labelRowsPerPage: "Cidadão(s) por página",
                },
                
              }}/>
          </Grid>
      
        {/* <MoreInfoDataGridModal 
          track={moreTrackInfo} 
          onHandleClose={()=>setMoreTrackInfo(null)}
        /> */}
        <SplashFeedback open={isSarchingData} size={100}  />
        <EditModalCrud citizenToEdit={selectedDataToEdit} onCloseModal={()=>{setSelectedDataToEdit(null)}}/>
      </ContainerLayout> 
      
    </MainLayout>
 
 
  );
}
